<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-th-large"></i><strong> Parcelles</strong>
              <info-message>
              
              </info-message>
              <div class="card-header-actions">
                <b-button variant="primary" :to="createParcelRoute">
                  <i class="fa fa-plus"></i>
                  Créer une parcelle
                </b-button>
              </div>
            </div>
            <div class="text-center" v-if="fetchingParcelList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-row v-else-if="parcels.length" class="parcels">
              <b-col lg="6" xl="4" v-for="parcel in parcels">
                <b-card :img-src="asset(parcel.picture)" no-body>
                  <div slot="header">
                    <!--this is to display a point that fadeIn and fadeOut for running productions-->
                    <div v-if="parcel.runningProductions.length !=0" class="d-inline-flex running-production">
                      &#8226;
                    </div>
                    <div class="d-inline">{{parcel.name}} ({{parcel.area}} ha)</div>
                    <div class="card-header-actions">
                      <div href="#" class="card-header-action">
                        <i class="fa fa-product-hunt" title="Productions" v-b-tooltip.hover></i>
                        <b-badge pill variant="danger" class="cursor-pointer" title="Production(s) en cours"
                                 v-b-tooltip.hover>{{parcel.runningProductions.length}}
                        </b-badge>
                        <b-badge pill variant="success" class="cursor-pointer" title="Production(s) planifiée(s)"
                                 v-b-tooltip.hover>{{parcel.plannedProductions.length}}
                        </b-badge>
                        <b-badge pill variant="success" class="cursor-pointer" title="Production(s) bloquée(s)"
                                 v-b-tooltip>
                          {{parcel.blockedProductions.length}}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                  <b-list-group flush>
                    <b-list-group-item>
                      <i class="fa fa-map-marker mr-2 text-danger"></i> {{parcel.localization.city}},
                      {{parcel.localization.country}}
                    </b-list-group-item>
                    <b-list-group-item>
                      <b-image :src="parcel.weather.iconUrl" class="weather-icon"></b-image>
                      {{parcel.weather.temperature}}&deg;C ({{parcel.weather.description}})
                    </b-list-group-item>
                  </b-list-group>
                  <div slot="footer">
                    <!--Plutard nous pourrons l'utiliser pour afficher l'historique de la parcelle-->
                    <!--<b-button variant="success" :to="parcel.url" title="Ouvrir" v-b-tooltip.hover>
                      <i class="icon-size-fullscreen"></i>
                    </b-button>-->
                    <b-button variant="primary" class="ml-2" :to="parcel.editUrl" title="Editer" v-b-tooltip.hover>
                      <i class="icon-note"></i>
                    </b-button>
                    <template v-if="parcel.runningProductions.length !=0">
                      <b-button variant="secondary" class="ml-2" title="Ouvrir la production en cours"
                                v-b-tooltip.hover
                                :to="'/exploitation/' + exploitationId + '/production/' + parcel.runningProductions[0].id">
                        <i class="fa fa-share-square-o"></i>
                      </b-button>
                    </template>
                    <b-button variant="light" class="ml-2" @click="openForecastModal(parcel.feature.center)"
                              title="Consulter les prévisions météos" v-b-tooltip.hover>
                      <i class="fa fa-cloud fa-2x"></i>
                    </b-button>
                    <b-modal title="Prévision Météo" class="modal-primary" v-model="meteoForecast"
                             size="lg">
                      
                      <div class="text-center" v-if="fetchingData">
                        <b-spinner></b-spinner>
                        <br>
                        Chargement...
                      </div>
                      
                      <div id="weather" v-else>
                        <div id="top">
                          <div class="location">{{currentWeather.city}},{{currentWeather.country}}</div>
                          <div class="time">
                            {{currentWeather.dayName}},
                            {{currentWeather.dayNumber}}
                            {{currentWeather.monthName}}
                            {{currentWeather.year}}
                          </div>
                          <div class="status">{{currentWeather.weather.description}}</div>
                        </div>
                        
                        <div id="left-information">
                          <img :src="currentWeather.weather.imgUrl"
                               :alt="currentWeather.weather.description" class="thumbnail" />
                          <div class="temperature">{{currentWeather.dayTemp}}</div>
                          <div class="unit">°C</div>
                        </div>
                        
                        <div id="right-information">
                          <span>Humidité: {{currentWeather.humidity}} %</span><br />
                          <span>Pression:  {{currentWeather.pressure}}  hPa</span><br />
                          <span>Vitesse du vent: {{currentWeather.windSpeed}}  m/s</span>
                        </div>
                        
                        <div id="forecast">
                          <ul>
                            <li v-for="(f, i) in forecastWeather" v-if="i < forecastWeather.length">
                              <div> {{f.dayName}}</div>
                              <img :src="f.weather.imgUrl" :alt="f.weather.description" />
                              <b>{{f.weather.description}}</b>
                              <min>{{f.minTemp}} °</min>
                              <max>{{ f.maxTemp }} °</max>
                            
                            </li>
                          </ul>
                        </div>
                      </div>
                    
                    </b-modal>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <p class="text-center" v-else>Aucune parcelle dans cette exploitation.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {
    Api,
    Regex,
    Toast,
    asset,
    Storage,
    env,
    getGeographicData,
    functionGeneratorJSON
  } from "../../../helpers/index";
  import axios from 'axios'
  import {endGuide} from "../../../helpers";
  
  export default {
    name: "ExploitationParcels",
    title: "PIA - Parcelles",
    data() {
      return {
        parcels: [],
        submitted: false,
        error: null,
        fetchingParcelList: false,
        
        // forecast weather data
        
        currentWeather: null,
        fetchingData: true,
        forecastWeather: [],
        meteoForecast: false,
      }
    },
    created() {
      this.fetchingParcelList = true
      Api.get('/exploitation/parcel/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            const parcelUrlPrefix = '/exploitation/' + this.exploitationId + '/parcels/'
            this.parcels = res.data.data.map((item, index) => {
              getGeographicData(item.feature.center, (err, data) => {
                if (err)
                  console.log(err)
                else {
                  this.parcels[index].weather = data.weather
                  this.parcels[index].localization = data.localization
                }
              })
              return {
                ...item,
                url: parcelUrlPrefix + item.id,
                editUrl: parcelUrlPrefix + 'create?edit=' + item.id,
                weather: {
                  description: '---'
                },
                localization: {
                  city: '---'
                },
                runningProductions: item.productions.filter(prod => prod.status.name == 'RUNNING'),
                plannedProductions: item.productions.filter(prod => prod.status.name == 'PLANNED'),
                blockedProductions: item.productions.filter(prod => prod.status.name == 'BLOCKED'),
              }
            })
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingParcelList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    mounted() {
      Toast.guide_msg(
        "Parcelle créée avec succès",
        this.createParcelRedirect,
        this.openWarehouse,
        functionGeneratorJSON("Créer une autre parcelle"),
        functionGeneratorJSON("Etape suivante"),
        1,
        [this.exploitationId],
        null,
        null,
        null,
        null,
        endGuide
      )
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      createParcelRoute() {
        return {
          name: 'ExploitationCreateParcel',
          params: {id: this.exploitationId}
        }
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    methods: {
      asset(path) {
        return asset(path, env('DEFAULT_PARCEL_PICTURE'))
      },
      async getWeatherForecast(long, lat) {
        try {
          let currentWeatherUrl = "https://api.openweathermap.org/data/2.5/weather?" +
            "lat=" + lat + "&lon=" + long + "&units=metric&lang=fr&appid=" +
            env('OPEN_WEATHER_MAP_KEY')
          
          let forecastWeatherUrl = "https://api.openweathermap.org/data/2.5/onecall?" +
            "lat=" + lat + "&lon=" + long + "&exclude=minutely,hourly,current&units=metric&lang=fr&appid=" +
            env('OPEN_WEATHER_MAP_KEY')
          
          let currentWeatherResponse = await axios.get(currentWeatherUrl)
          let forecastWeatherResponse = await axios.get(forecastWeatherUrl)
          
          if (currentWeatherResponse.data && forecastWeatherResponse.data) {
            let days = ["Dim", 'Lun', 'Mar', "Mer", "Je", "Ven", "Sam"]
            var monthShortNames = ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin",
              "Juil", "Aou", "Sept", "Oct", "Nov", "Dec"
            ];
            
            let forecast = forecastWeatherResponse.data.daily.slice(1)
            
            let date = new Date(currentWeatherResponse.data.dt * 1000)
            this.currentWeather = {
              dayName: days[date.getDay()],
              monthName: monthShortNames[date.getMonth()],
              dayNumber: date.getDate(),
              year: date.getFullYear(),
              country: currentWeatherResponse.data.sys.country,
              city: currentWeatherResponse.data.name,
              weather: {
                main: currentWeatherResponse.data.weather[0].main,
                description: currentWeatherResponse.data.weather[0].description[0].toUpperCase() +
                currentWeatherResponse.data.weather[0].description.slice(1),
                imgUrl: this.getImage(currentWeatherResponse.data.weather[0].icon)
              },
              minTemp: currentWeatherResponse.data.main.temp_min,
              maxTemp: currentWeatherResponse.data.main.temp_max,
              dayTemp: currentWeatherResponse.data.main.temp,
              pressure: currentWeatherResponse.data.main.pressure,
              windSpeed: currentWeatherResponse.data.wind.speed,
              humidity: currentWeatherResponse.data.main.humidity
            }
            
            this.forecastWeather = forecast.map((item) => {
              console.log("====================================================================")
              console.log("forecast: ", item)
              console.log("====================================================================")
              console.log("descritpion : ", item.weather[0].description[0].toUpperCase() +
                item.weather[0].description.slice(1))
              
              return {
                dayName: days[new Date(item.dt * 1000).getDay()],
                minTemp: item.temp.min,
                maxTemp: item.temp.max,
                dayTemp: item.temp.day,
                pressure: item.pressure,
                windSpeed: item.wind_speed,
                weather: {
                  main: item.weather[0].main,
                  description: item.weather[0].description[0].toUpperCase() +
                  item.weather[0].description.slice(1),
                  imgUrl: this.getImage(item.weather[0].icon)
                },
                precipitation: item.rain,
                humidity: item.humidity
              }
            })
          }
          
          this.fetchingData = false
          console.log("Fini")
        } catch (e) {
          console.log(e)
          this.error = "Erreur pendant le chargement, Veuillez réessayer."
        }
      },
      getImage(name) {
        return "http://openweathermap.org/img/wn/" + name + "@2x.png"
      },
      async openForecastModal(center) {
        this.forecastWeather = []
        this.currentWeather = null
        
        this.meteoForecast = true
        this.fetchingData = true
        await this.getWeatherForecast(center[0], center[1])
      },
      createParcelRedirect() {
        this.$router.push('/exploitation/' + this.exploitationId + '/parcels/create')
      },
      openWarehouse() {
        this.$router.push('/exploitation/' + this.exploitationId + '/management/warehouses/create')
      },
    }
  }
</script>

<style scoped>
  .card-img{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .parcels .card-body{
    /*height: 300px;*/
    text-overflow: ellipsis;
  }
  
  .weather-icon{
    max-width: 32px;
    max-height: 32px;
    margin-left: -8px;
    margin-top: -5px;
    margin-bottom: -5px;
  }
  
  .running-production{
    font-weight: bold;
    color: #009000;
    margin-left: 5px;
    font-size: 103%;
    animation: runningAnimation 800ms linear infinite alternate;
    position: absolute;
    left: 5px;
  }
  
  @keyframes runningAnimation{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
    
  }
  
  /* Weather */
  #weather{
    margin: 0 10px;
    max-width: 632px;
  }
  
  #top{
    margin-bottom: 20px;
  }
  
  #top .location{
    font-size: 24px;
    line-height: 1.2;
  }
  
  #top .time{
    font-size: 16px;
    line-height: 2;
  }
  
  #top .status{
    font-size: 13px;
    line-height: 1.4
  }
  
  #left-information{
    color: #212121;
  }
  
  #left-information .thumbnail{
    float: left;
    height: 150px;
    width: 150px;
  }
  
  #left-information .temperature{
    float: left;
    margin-top: -3px;
    padding-left: 10px;
    font-size: 64px;
  }
  
  #left-information .unit{
    float: left;
    margin-top: 6px;
    font-size: 20px;
  }
  
  #right-information{
    float: right;
    padding-left: 5px;
    padding-top: 2px;
    min-width: 43%;
    font-weight: 600;
  }
  
  #forecast{
    padding-top: 10px;
    clear: both;
    display: block;
  }
  
  #forecast ul{
    padding: 0;
    margin: 15px 0;
    display: flex;
  }
  
  #forecast ul li{
    display: inline-block;
    max-width: 100px;
    text-align: center;
    line-height: 1;
  }
  
  #forecast ul li min{
    display: block;
    margin-top: 5px;
  }
  #forecast ul li max{
    display: block;
    margin-top: 2px;
  }


</style>
